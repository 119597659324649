import axiosInstance from "@/axios";

export default {
    login(un, pw) {
        console.debug("AuthFactory login");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/auth/login", { username: btoa(un), password: btoa(pw) })
                .then(response => {
                    console.debug("login response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("login error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    loginToken(token) {
        console.debug("AuthFactory login");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/auth/login", token)
                .then(response => {
                    console.debug("login response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("login error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getSiteHealth() {
        console.debug("AuthFactory getSiteHealth");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/api/ping")
                .then(response => {
                    console.debug("getSiteHealth response: ", response);
                    resolve(response); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getSiteHealth error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    getUserInfo() {
        console.debug("AuthFactory getUserInfo");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/auth/info")
                .then(response => {
                    console.debug("getUserInfo response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getUserInfo error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    logout() {
        console.debug("AuthFactory logout");
        return new Promise((resolve, reject) => {
            axiosInstance.get("/auth/logout")
                .then(response => {
                    console.debug("logout response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("logout error: ", error);
                    reject(error); // Reject with the error
                });
        })
    },

    createUser(un, em, cl, pe) {
        console.debug("AuthFactory createUser");
        return new Promise((resolve, reject) => {
            axiosInstance.post("/auth/user/new", { username: un, email: em, client: cl, permissions: pe })
                .then(response => {
                    console.debug("createUser response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("createUser error: ", error);
                    reject(error); // Reject with the error
                });
        });
    },

    changePassword(pw) {
        console.debug("AuthFactory changePassword");
        return new Promise((resolve, reject) => {
            axiosInstance.put("/auth/user/password", { password: btoa(pw) })
                .then(response => {
                    console.debug("changePassword response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("changePassword error: ", error);
                    reject(error); // Reject with the error
                });
        });
    }
}
