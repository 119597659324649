import {createApp} from 'vue';

import "primeflex/primeflex.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "../node_modules/prismjs/themes/prism-coy.css";
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import './style/main.css'

import App from './App.vue';
import router from './router';
import store from './store';
import preferences from './cookies/preferences';
import factories from '@/factories';
import axiosInstance from "@/axios";
import mitt from 'mitt';
import Card from 'primevue/card';
import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import BadgeDirective from 'primevue/badgedirective';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import DeferredContent from 'primevue/deferredcontent';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Password from 'primevue/password';
import Paginator from "primevue/paginator";
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import Ripple from 'primevue/ripple';
import Skeleton from 'primevue/skeleton';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import ContextMenu from 'primevue/contextmenu';
import Textarea from 'primevue/textarea';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import VueCookies from 'vue3-cookies'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import AutoComplete from 'primevue/autocomplete';
import Toolbar from 'primevue/toolbar';
import SelectButton from 'primevue/selectbutton';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import OverlayPanel from 'primevue/overlaypanel';
import VueConfetti from 'vue-confetti'
import cronLight from '@vue-js-cron/light'
import '@vue-js-cron/light/dist/light.css'
import VueApexCharts from "vue3-apexcharts";
import VNetworkGraph from "v-network-graph"


// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilm, faBook, faDroplet, faDropletSlash, faHammer } from '@fortawesome/free-solid-svg-icons'
library.add(faFilm, faBook, faDroplet, faDropletSlash, faHammer)


// globalCookiesConfig({
//     expireTimes: "30d",
//     path: "/",
//     domain: "",
//     secure: true,
//     sameSite: "None",
// });

const app = createApp(App);

// const emitter = mitt();
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(store);
app.use(router);
app.use(PerfectScrollbar);
app.use(VueCookies);
app.use(ConfirmationService);
app.use(VueConfetti);
app.use(cronLight);
app.use(VueApexCharts);
app.use(VNetworkGraph);


app.config.globalProperties.$factory = factories;
app.config.globalProperties.$axios = axiosInstance;
app.config.globalProperties.$preferences = preferences;
app.config.globalProperties.$emitter = mitt();

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('DeferredContent', DeferredContent);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('Password', Password);
app.component('Paginator', Paginator);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('Skeleton', Skeleton);
app.component('Sidebar', Sidebar);
app.component('Slider', Slider);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('ToggleButton', ToggleButton);
app.component('Toast', Toast);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Row', Row);
app.component('AutoComplete', AutoComplete);
app.component('Toolbar', Toolbar);
app.component('SelectButton', SelectButton);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('OverlayPanel', OverlayPanel);
app.component('font-awesome-icon', FontAwesomeIcon);

app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);


app.mount('#app');
