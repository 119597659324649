import axiosInstance from "@/axios";

export default {
    getAllCyberlockers() {
        console.debug("CyberlockerFactory getAllCyberlockers")
        return new Promise((resolve, reject) => {
            axiosInstance.get("/cyberlocker/all")
                .then(response => {
                    console.debug("getAllCyberlockers response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getAllCyberlockers error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    resetCyberlockers(assetId) {
        console.debug("CyberlockerFactory resetCyberlockers")
        let params = new URLSearchParams();
        params.append("asset_id", assetId);
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/cyberlocker/reset?${params.toString()}`)
                .then(response => {
                    console.debug("resetCyberlockers response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("resetCyberlockers error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getOverview() {
        console.debug("CyberlockerFactory getOverview")
        return new Promise((resolve, reject) => {
            axiosInstance.get("/cyberlocker/overview")
                .then(response => {
                    console.debug("getOverview response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getOverview error: ", error);
                    reject(error); // Reject with the error
                })
        });
    },

    getNetworkGraph() {
        console.debug("CyberlockerFactory getNetworkGraph")
        return new Promise((resolve, reject) => {
            axiosInstance.get("/cyberlocker/graph/network")
                .then(response => {
                    console.debug("getNetworkGraph response: ", response);
                    resolve(response.data); // Resolve with the response data
                })
                .catch(error => {
                    console.error("getNetworkGraph error: ", error);
                    reject(error); // Reject with the error
                })
        });
    }
}
